























































































































































import ModelConfigService from '../../services/ModelConfigService';
import { Component, Watch } from 'vue-property-decorator';
import ModelConfig from '@/models/ModelConfig';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import Project from '@/models/Project';
import { exportConfig } from '@/views/configs/ConfigExporter';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';

@Component
export default class ModelConfigs extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction, project?: Project): boolean {
    const permission = hasPermission(action, project);
    return permission != null && permission;
  }

  protected readonly breadcrumbs = [
    { text: 'Home', to: '/' },
    { text: 'Model Configs', active: true },
  ];

  protected readonly reader: FileReader = new FileReader();

  protected configs: ModelConfig[] = [];

  protected configCreationSelectedFile: File | null = null;
  protected configCreationValidatorState: boolean | null = null;
  protected configCreationFileContent = '';
  protected configCreationModalSubmitText = 'Upload';
  protected configCreationInProgress = false;
  protected configCreationErrorMessage = '';

  @Watch('configCreationSelectedFile')
  protected handleFileUploadChanged(newVal: File | null): void {
    if (newVal) {
      this.readSelectedFile();
    }
  }

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_GET_CONFIG)) {
      this.$on('finished-delete-config', (args) => {
        this.configs.splice(this.configs.indexOf(args.config), 1);
      });

      this.loadConfigs();
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  beforeDestroy(): void {
    this.$off('finished-delete-config');
  }

  protected loadConfigs(): void {
    if (hasPermission(PossibleAction.CAN_GET_CONFIG)) {
      this.setLoading(true);
      ModelConfigService.getAll()
        .then((configs) => {
          this.showToast('Successfully loaded', configs.length + ' Model Configs found', 'info');
          this.configs = configs;
        })
        .catch((backendError) => {
          console.error(backendError);
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast('Failed to load', 'Could not load model configs.', 'danger');
          }
        })
        .finally(() => this.setLoading(false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected readSelectedFile(): void {
    if (this.configCreationSelectedFile) {
      this.reader.onloadstart = () => {
        this.configCreationInProgress = true;
      };
      this.reader.onload = () => {
        if (this.reader.result) {
          this.configCreationFileContent = this.reader.result.toString();
        }
        this.configCreationValidatorState = true;
        this.configCreationModalSubmitText = 'Upload';
      };
      this.reader.onerror = () => {
        this.configCreationValidatorState = false;
        this.showToast('Failed to read', 'Could not read the file.', 'danger');
      };
      this.reader.onloadend = () => {
        this.configCreationInProgress = false;
      };
      this.reader.readAsText(this.configCreationSelectedFile);
    } else {
      this.configCreationValidatorState = false;
      this.configCreationErrorMessage = 'Required';
    }
  }

  protected createConfig(): void {
    if (hasPermission(PossibleAction.CAN_SAVE_CONFIG)) {
      if (this.configCreationFileContent.length > 0) {
        this.configCreationInProgress = true;
        ModelConfigService.saveByString(this.configCreationFileContent)
          .then((savedConfig) => {
            this.$nextTick(() => {
              this.showToast(
                'Successfully created',
                "'" + savedConfig.modelType + ' ' + savedConfig.version + "' successfully created.",
                'success'
              );
              this.configs.unshift(savedConfig);
              this.$bvModal.hide('modal-create');
            });
          })
          .catch((backendError) => {
            if (backendError.response.status === 400) {
              if (!backendError.response.data) {
                this.configCreationErrorMessage = 'Model Config has an invalid syntax.';
              } else {
                this.configCreationErrorMessage =
                  'Model Config has an invalid form. Error: ' + backendError.response.data.message;
              }
            } else if (backendError.response.status === 403) {
              this.$bvModal.hide('modal-create');
              this.showToast('Action denied', 'You do not have the required rights.', 'danger');
            } else if (backendError.response.status === 409) {
              this.configCreationErrorMessage = 'Model Config for given type and version already exists.';
            } else {
              this.configCreationErrorMessage = 'An unexpected error occurred while trying to parse the Model Config.';
            }
            this.configCreationValidatorState = false;
            this.configCreationModalSubmitText = 'Upload';
          })
          .finally(() => (this.configCreationInProgress = false));
      } else {
        this.configCreationValidatorState = false;
        this.configCreationErrorMessage = 'Required';
      }
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected deleteConfig(config: ModelConfig): void {
    if (hasPermission(PossibleAction.CAN_DELETE_CONFIG)) {
      this.$root.$emit('delete-config', { config, target: this });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected exportConfig(config: ModelConfig): void {
    exportConfig(config);
  }

  protected changeConfigState(config: ModelConfig, active: boolean): void {
    if (hasPermission(PossibleAction.CAN_UPDATE_CONFIG)) {
      this.$root.$emit('change-config-state', { config, active });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected openConfigDetails(config: ModelConfig): void {
    this.$router.push({
      name: 'configDetails',
      params: { type: config.modelType, version: config.version },
    });
  }

  protected configCreationSubmitted(modalEvent: Event): void {
    modalEvent.preventDefault();
    if (this.configCreationModalSubmitText === 'Upload') {
      this.createConfig();
    } else {
      this.readSelectedFile();
    }
  }

  protected configCreationReset(): void {
    this.configCreationFileContent = '';
    this.configCreationSelectedFile = null;
    this.configCreationValidatorState = null;
    this.configCreationModalSubmitText = 'Upload';
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}
