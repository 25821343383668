import ModelConfig from '@/models/ModelConfig';
import { serialize } from 'typescript-json-serializer';

const exportConfig = (config: ModelConfig): void => {
  // pretty print the json, to make it easy to read for humans
  const configJsonText = JSON.stringify(serialize(config), null, '\t');

  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(configJsonText));
  const fileName = 'config_' + config.modelType + '_' + config.version + '.json';
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export { exportConfig };
